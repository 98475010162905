<template>
    <div class="footer">
        <div class="footer__container">
            <div class="menu__container">
                <div class="menu">
                    <div class="menu__headline">Produkte</div>
                    <nuxt-link to="/online-shop" class="menu__item"
                        >Shop</nuxt-link
                    >
                    <nuxt-link to="/online-backoffice" class="menu__item"
                        >Backoffice</nuxt-link
                    >
                    <nuxt-link to="/galerie" class="menu__item"
                        >Galerie</nuxt-link
                    >
                    <nuxt-link to="/kiosk" class="menu__item">Kiosk</nuxt-link>
                    <nuxt-link to="/ordermanager" class="menu__item"
                        >Ordermanager</nuxt-link
                    >
                </div>
                <div class="menu">
                    <div class="menu__headline">Über</div>
                    <nuxt-link to="/kontakt" class="menu__item"
                        >Kontakt</nuxt-link
                    >
                    <nuxt-link to="/impressum" class="menu__item"
                        >Impressum</nuxt-link
                    >
                    <nuxt-link to="/datenschutz" class="menu__item"
                        >Datenschutz</nuxt-link
                    >
                </div>
                <div class="menu">
                    <div class="menu__headline">API</div>
                    <nuxt-link to="/api" class="menu__item"
                        >Anleitung</nuxt-link
                    >
                    <nuxt-link to="/api-demo" class="menu__item"
                        >Demo</nuxt-link
                    >
                </div>
            </div>
            <nuxt-link
                class="footer__logo"
                to="/"
                aria-label="Link zur Startseite"
            >
                <logo color="#6b798d" />
            </nuxt-link>
        </div>
    </div>
</template>

<script>
import Logo from '~/components/logo.vue';

export default {
    components: {
        Logo,
    },
};
</script>

<style lang="scss" scoped>
.footer {
    background-color: $light_blue_02;
    padding: 40px 0;

    @include breakpoint(xs) {
        padding: 80px 0;
    }

    &__container {
        @include container;
        display: flex;
        justify-content: space-between;
    }

    &__logo {
        flex-shrink: 0;
        align-self: flex-start;
    }
}

.menu {
    margin-bottom: 30px;

    &:last-child {
        margin-bottom: 0;
    }

    @include breakpoint(xs) {
        margin-right: 90px;
        margin-bottom: 0;
    }

    &__headline {
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: $dark_blue_02;
        text-transform: uppercase;
        margin-bottom: 15px;
    }

    &__item {
        display: block;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: $dark_blue_02;
        margin-bottom: 12px;
        transition: 0.3s all;

        &:last-child {
            margin-bottom: 0;
        }

        &:hover {
            opacity: 0.75;
        }
    }

    &__container {
        @include breakpoint(xs) {
            display: flex;
        }
    }
}
</style>
