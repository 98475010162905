<template>
    <div>
        <div :class="['header', { 'header--with-shadow': scrolled }]">
            <div class="header__container">
                <nuxt-link
                    class="header__logo"
                    to="/"
                    aria-label="Link zur Startseite"
                >
                    <logo width="156" height="30" />
                </nuxt-link>

                <div :class="['menu', { 'menu--show': showMobileMenu }]">
                    <div
                        :class="[
                            'menu__item',
                            {
                                'menu__item--active': isOnlineRoute,
                            },
                        ]"
                    >
                        <span
                            v-click-outside="() => (showOnlineSub = false)"
                            class="menu__item-cta"
                            @click="showOnlineSub = !showOnlineSub"
                            >Online</span
                        >
                        <transition name="slide-down">
                            <div v-if="showOnlineSub" class="sub-menu">
                                <nuxt-link
                                    to="/online-shop"
                                    active-class="sub-menu__item--active"
                                    class="sub-menu__item"
                                >
                                    <div class="sub-menu__item-image-wrapper">
                                        <img
                                            v-lazy-load
                                            class="sub-menu__item-image"
                                            src="~/assets/shop/stage.png"
                                            alt="Shop"
                                        />
                                    </div>
                                    <div class="sub-menu__item-text">Shop</div>
                                </nuxt-link>
                                <nuxt-link
                                    to="/online-backoffice"
                                    active-class="sub-menu__item--active"
                                    class="sub-menu__item"
                                >
                                    <div class="sub-menu__item-image-wrapper">
                                        <img
                                            v-lazy-load
                                            class="sub-menu__item-image sub-menu__item-image--with-shadow"
                                            src="~/assets/backoffice/top-header.jpg"
                                            alt="Backoffice"
                                        />
                                    </div>
                                    <div class="sub-menu__item-text">
                                        Backoffice
                                    </div>
                                </nuxt-link>
                            </div>
                        </transition>
                    </div>
                    <nuxt-link
                        to="/galerie"
                        active-class="menu__item--active"
                        class="menu__item"
                        >Galerie</nuxt-link
                    >
                    <nuxt-link
                        to="/marktplatz"
                        active-class="menu__item--active"
                        class="menu__item"
                        >Marktplatz</nuxt-link
                    >
                    <nuxt-link
                        to="/kiosk"
                        active-class="menu__item--active"
                        class="menu__item"
                        >Kiosk</nuxt-link
                    >
                    <nuxt-link
                        to="/ordermanager"
                        active-class="menu__item--active"
                        class="menu__item"
                        >Ordermanager</nuxt-link
                    >
                    <nuxt-link
                        to="/preise"
                        active-class="menu__item--active"
                        class="menu__item"
                        >Preise</nuxt-link
                    >
                    <nuxt-link
                        to="/kontakt"
                        active-class="menu__item--active"
                        class="menu__item"
                        >Kontakt</nuxt-link
                    >
                </div>

                <a
                    target="_blank"
                    href="https://www.coppio.app/live-demo"
                    class="cta-button cta-button--visible-mobile"
                    >Demo<arrow-right-circle-icon
                /></a>

                <a
                    target="_blank"
                    href="https://www.coppio.app/live-demo"
                    class="cta-button cta-button--hidden-mobile"
                    >Live-Demo testen<arrow-right-circle-icon
                /></a>

                <burger
                    :is-open="showMobileMenu"
                    @toggle-burger="showMobileMenu = !showMobileMenu"
                />
            </div>
        </div>
        <div class="header-spacer"></div>
    </div>
</template>

<script>
import { ArrowRightCircleIcon } from 'vue-feather-icons';
import ClickOutside from 'vue-click-outside';
import Logo from '~/components/logo.vue';
import Burger from '~/components/burger.vue';

export default {
    directives: {
        ClickOutside,
    },

    components: {
        Logo,
        Burger,
        ArrowRightCircleIcon,
    },

    data() {
        return {
            scrolled: false,
            showOnlineSub: false,
            showMobileMenu: false,
        };
    },

    computed: {
        isOnlineRoute() {
            return (
                this.$nuxt.$route.path === '/online-shop' ||
                this.$nuxt.$route.path === '/online-backoffice'
            );
        },
    },

    mounted() {
        this.scrolled = window.scrollY > 50;
    },

    beforeMount() {
        window.addEventListener('scroll', this.handleScroll);
    },

    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },

    methods: {
        handleScroll() {
            this.scrolled = window.scrollY > 50;
        },
    },
};
</script>

<style lang="scss" scoped>
.header-spacer {
    height: 75px;
    width: 100%;
    position: relative;

    @include breakpoint(xs) {
        height: 90px;
    }
}

.header {
    background-color: white;
    height: 75px;
    width: 100%;
    position: fixed;
    z-index: 100;
    transition: 0.3s all;

    @include breakpoint(xs) {
        height: 90px;
    }

    &--with-shadow {
        box-shadow: 0px 0px 15px rgba(54, 65, 75, 0.12);
    }

    &__container {
        @include container;
        height: 100%;
        display: flex;
        align-items: center;
    }

    &__logo {
        flex-shrink: 0;
        display: flex;
        align-items: center;

        @media (max-width: 850px) {
            svg {
                width: 130px;
            }
        }
    }
}

.menu {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: $light_grey_03;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    margin-top: 100vh;
    transition: 0.3s all;

    &--show {
        opacity: 1;
        visibility: visible;
        margin-top: 75px;
        z-index: 10;

        @include breakpoint(xs) {
            margin-top: 90px;
        }
    }

    @include breakpoint(xs) {
        opacity: 1;
        visibility: visible;
        z-index: 1;
        margin-left: 10px;
        margin-top: 0;
        top: auto;
        left: auto;
        background: none;
        position: relative;
        display: flex !important;
        flex-direction: row;
        align-items: center;
        height: auto;
        width: auto;
    }

    @include breakpoint(sm) {
        margin-left: 15px;
    }

    &__item {
        position: relative;
        color: $dark_blue_01;
        transition: 0.3s all;
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid $light_blue_02;

        @include breakpoint(xs) {
            width: auto;
            height: auto;
            padding: 6px 10px;
            font-size: 14px;
            line-height: 18px;
            border-top: 0;
        }

        @include breakpoint(sm) {
            padding: 6px 14px;
            margin-right: 2px;
            font-size: 15px;
        }

        span {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
            cursor: pointer;
        }

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            color: $red;
        }

        &--active {
            background: $light_blue_03;
            border-radius: 3px;
            font-weight: 600;
        }
    }
}

.sub-menu {
    position: absolute;
    z-index: 20;
    width: calc(100% - 40px);
    left: 20px;
    top: 59px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    box-shadow: 0px 0px 30px rgba(54, 65, 75, 0.15);
    border-radius: 5px;

    @include breakpoint(xs) {
        flex-direction: row;
        top: 40px;
        width: auto;
        left: auto;
    }

    &:before {
        bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: '';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-bottom-color: white;
        border-width: 6px;
        margin-left: -6px;
    }

    &__item {
        padding: 15px;
        margin: 15px;
        border-radius: 5px;
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: 0.3s all;
        cursor: pointer;
        position: relative;
        background-color: white;

        @include breakpoint(xs) {
            width: 50%;
        }

        &:hover {
            background-color: $light_blue_03;
        }

        &--active {
            background-color: $light_grey_01;
        }

        &:last-child {
            &:after {
                content: '';
                position: absolute;
                width: calc(100% + 60px);
                top: -15px;
                left: -30px;
                height: 1px;
                background-color: $light_blue_02;

                @include breakpoint(xs) {
                    left: -15px;
                    top: 15px;
                    height: calc(100% - 30px);
                    width: 1px;
                }
            }
        }

        &-image {
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;

            &-wrapper {
                width: 140px;
                height: 70px;
                text-align: center;
            }

            &--with-shadow {
                box-shadow: 0px 4px 40px rgba(54, 65, 75, 0.15);
            }
        }

        &-text {
            margin-top: 20px;
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
            text-align: center;
            color: $dark_blue_01;
        }
    }
}

.cta-button {
    flex-shrink: 0;
    margin-left: auto;
    background: rgba(229, 102, 95, 0.15);
    border-radius: 3px;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    display: none;
    align-items: center;
    color: $red;
    height: 30px;
    padding: 0 10px;
    transition: 0.3s all;

    @include breakpoint(xs) {
        display: flex;
        height: 35px;
    }

    @include breakpoint(sm) {
        padding: 0 15px;
    }

    &:hover {
        opacity: 0.75;
    }

    svg {
        margin-left: 10px;
        flex-shrink: 0;
        width: 20px;
        height: 20px;

        @include breakpoint(xs) {
            display: none;
        }

        @media (min-width: 800px) {
            display: block;
        }
    }

    &--visible-mobile {
        display: flex;
        position: absolute;
        right: 75px;

        @media (max-width: 380px) {
            svg {
                display: none;
            }
        }

        @include breakpoint(xs) {
            right: 30px;
        }

        @include breakpoint(sm) {
            display: none;
        }
    }

    &--hidden-mobile {
        display: none;

        @include breakpoint(sm) {
            display: flex;
        }
    }
}
</style>
