<template>
    <div
        :class="['burger', { 'burger--active': isOpen }]"
        @click="$emit('toggle-burger')"
    >
        <span></span>
    </div>
</template>

<script>
export default {
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss">
.burger {
    width: 30px;
    height: 24px;
    overflow: hidden;
    transition: 0.3s;
    cursor: pointer;
    margin-left: auto;

    @include breakpoint(xs) {
        display: none;
    }

    span {
        float: right;
        width: 30px;
        border-radius: 3px;
        height: 3px;
        background-color: #444;
        position: relative;
        top: 10px;
        transition: 0.3s;

        &:before {
            content: '';
            width: 30px;
            border-radius: 3px;
            height: 3px;
            background-color: #444;
            position: absolute;
            top: -8px;
            left: 0;
        }

        &:after {
            content: '';
            width: 30px;
            border-radius: 3px;
            height: 3px;
            background-color: #444;
            position: absolute;
            bottom: -8px;
            left: 0;
        }
    }

    &--active {
        transform: rotate(360deg);

        span {
            background-color: transparent;

            &:before {
                top: 0;
                transform: rotate(-45deg);
            }

            &:after {
                bottom: 0;
                transform: rotate(45deg);
            }
        }
    }
}
</style>
